/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactComponent as InstavisionCannotAddDeviceIcon } from 'assets/CannotAddSelectedCamera.svg';
import { ReactComponent as InstavisionStartFreeTrialIllustration } from 'assets/free-trial-logo.svg';

import { ReactComponent as LoginIllustration } from 'assets/loginIllustration.svg';
import { ReactComponent as InstavisionMultiCameraAddedIllustration } from 'assets/multiCameraIcon.svg';
import InstavisionLogo from 'assets/instavisionLogo.png';
import { ReactComponent as InstavisionPaymentDeclinedIllustration } from 'assets/payment-failed-icon.svg';
import { ReactComponent as InstavisionPaymentSuccessfulIllustration } from 'assets/payment-success-illustration.svg';
import { ReactComponent as InstavisionFreeTrialActivationIllustration } from 'assets/trial-activation-illustration.svg';

// Instaview Promotion Page Images
import InstaViewBasicAlerts from 'assets/freeTrial/instaviewBasicAlerts.png';
import InstaViewProfessionalMonitoring from 'assets/freeTrial/instaviewProfessionalMonitoring.png';
import InstaViewDescriptiveAlerts from 'assets/freeTrial/instaviewDescriptiveAIAlerts.png';
import LimitedSecurity from 'assets/freeTrial/limitedSecurity.png';
import EventVideos30Days from 'assets/freeTrial/30DaysEventVideos.png';
import NoEventVideos from 'assets/freeTrial/noEventVideos.png';
import EventVideos30DaysBlur from 'assets/freeTrial/30DaysEventVideos-small.png';

// LUNA/
import { ReactComponent as LunaAllCamerasAddedToAccount } from 'assets/luna/AllCamerasAddedToAccount.svg';
import { ReactComponent as LunaCannotAddSelectedCamera } from 'assets/luna/CannotAddSelectedCamera.svg';
import { ReactComponent as LunaFreeTrialActivated } from 'assets/luna/FreeTrialActivated.svg';
import { ReactComponent as LunaAppLandingLogo } from 'assets/luna/LunaAppLandingLogo.svg';
import LunaAppLogo from 'assets/luna/lunaAppLogo.png';
import { ReactComponent as LunaPaymentDeclined } from 'assets/luna/PaymentDeclined.svg';
import { ReactComponent as LunaPaymentSuccessful } from 'assets/luna/PaymentSuccessful.svg';
import { ReactComponent as LunaStartFreeTrial } from 'assets/luna/StartFreeTrial.svg';
import LunaSmallWhiteLogo from 'assets/luna/LunaLogo.png';

// Luna Promotion Page Images
import LunaProfessionalMonitoring from 'assets/luna/lunaProfessionalMonitoring.png';
import LunaDescriptiveAlerts from 'assets/luna/lunaDescriptiveAlerts.png';
import LunaBasicAlerts from 'assets/luna/lunaBasicAlert.png';

import { TFunction } from 'i18next';
import {
  PARTNER_NAME,
  PARTNER_SHORT_NAME,
  PARTNER_UNIVERSAL_LINK
} from './config';

export interface TPartnerConfigurationDetails {
  nameOfCompany?: string;
  typeOfCompany?: string;
  singinLabel?: string;
  welcomeText?: string;
  loginIllustration?: any;
  logo?: any;
  universalLink?: string;
  paymentSuccessfull?: any;
  paymentFailed?: any;
  freeTrialLogo?: any;
  multiCameraAddedIllustration?: any;
  cannotAddDeviceIcon?: any;
  trialActivationIcon?: any;
  label?: string;
  supportEmail?: string;
  phoneNumber?: string;
  companyAddress?: string;
  freeTrialLivingRoom?: any;
  freeTrialLivingRoomBlur?: any;
  freeTrialPersonDetected?: any;
  freeTrialCriticalAlerts?: any;
  skipFreeTrialLivingRoom?: any;
  skipFreeTrialTheft?: any;
  skipFreeTrialFalseAlerts?: any;
  updateDeteOfPrivacyPolicy?: string;
  updateDeteOfTermsOfService?: string;
  supportEmail2?: string;
  smallWhiteLogo?: any;
}

export interface TPartnerConfiguration {
  [PARTNER_NAME: string]: TPartnerConfigurationDetails;
}

export const getPartnerConfiguration = (
  partnerId: string | number,
  t: TFunction<'translation', 'partnerConfig'>
): TPartnerConfigurationDetails => {
  const safeTranslate = (key: string, options?: any, fallback?: string) => {
    try {
      const translation = t(key, options);
      return translation !== key ? translation : fallback || key;
    } catch (error) {
      console.warn(`Translation failed for key: ${key}`, error);
      return fallback || key;
    }
  };

  const partnerConfigs = {
    [PARTNER_NAME.DEFAULT]: {
      nameOfCompany: 'InstaVision',
      typeOfCompany: 'Inc.',
      singinLabel: safeTranslate('signIn', undefined, 'Sign In') as string,
      welcomeText: safeTranslate(
        'welcomeText',
        {
          partnerName: 'InstaVision'
        },
        'Welcome to InstaVision'
      ) as string,
      loginIllustration: LoginIllustration,
      logo: InstavisionLogo,
      universalLink: PARTNER_UNIVERSAL_LINK[PARTNER_NAME.DEFAULT],
      paymentSuccessfull: InstavisionPaymentSuccessfulIllustration,
      paymentFailed: InstavisionPaymentDeclinedIllustration,
      freeTrialLogo: InstavisionStartFreeTrialIllustration,
      multiCameraAddedIllustration: InstavisionMultiCameraAddedIllustration,
      cannotAddDeviceIcon: InstavisionCannotAddDeviceIcon,
      trialActivationIcon: InstavisionFreeTrialActivationIllustration,
      label: 'InstaVision',
      supportEmail: 'support@instavision.ai',
      supportEmail2: 'info@instavision.ai',
      phoneNumber: '+1 (877) 916-3025',
      companyAddress: '2244 Faraday Avenue, Carlsbad, CA 92008',
      freeTrialLivingRoom: EventVideos30Days,
      freeTrialLivingRoomBlur: EventVideos30DaysBlur,
      freeTrialPersonDetected: InstaViewDescriptiveAlerts,
      freeTrialCriticalAlerts: InstaViewProfessionalMonitoring,
      skipFreeTrialLivingRoom: NoEventVideos,
      skipFreeTrialTheft: LimitedSecurity,
      skipFreeTrialFalseAlerts: InstaViewBasicAlerts,
      updateDeteOfPrivacyPolicy: 'July 09, 2024',
      updateDeteOfTermsOfService: 'July 10, 2024',
      smallWhiteLogo: InstavisionLogo
    },
    [PARTNER_NAME.LOCAL]: {
      nameOfCompany: 'InstaVision',
      typeOfCompany: 'Inc.',
      singinLabel: safeTranslate('signIn', undefined, 'Sign In') as string,
      welcomeText: safeTranslate(
        'welcomeText',
        {
          partnerName: 'InstaVision'
        },
        'Welcome to InstaVision'
      ) as string,
      loginIllustration: LoginIllustration,
      logo: InstavisionLogo,
      universalLink: PARTNER_UNIVERSAL_LINK[PARTNER_NAME.LOCAL],
      paymentSuccessfull: InstavisionPaymentSuccessfulIllustration,
      paymentFailed: InstavisionPaymentDeclinedIllustration,
      freeTrialLogo: InstavisionStartFreeTrialIllustration,
      multiCameraAddedIllustration: InstavisionMultiCameraAddedIllustration,
      cannotAddDeviceIcon: InstavisionCannotAddDeviceIcon,
      trialActivationIcon: InstavisionFreeTrialActivationIllustration,
      label: 'InstaVision',
      supportEmail: 'support@instavision.ai',
      supportEmail2: 'info@instavision.ai',
      phoneNumber: '+1 (877) 916-3025',
      companyAddress: '2244 Faraday Avenue, Carlsbad, CA 92008',
      freeTrialLivingRoom: EventVideos30Days,
      freeTrialLivingRoomBlur: EventVideos30DaysBlur,
      freeTrialPersonDetected: InstaViewDescriptiveAlerts,
      freeTrialCriticalAlerts: InstaViewProfessionalMonitoring,
      skipFreeTrialLivingRoom: NoEventVideos,
      skipFreeTrialTheft: LimitedSecurity,
      skipFreeTrialFalseAlerts: InstaViewBasicAlerts,
      updateDeteOfPrivacyPolicy: 'July 09, 2024',
      updateDeteOfTermsOfService: 'July 10, 2024',
      smallWhiteLogo: InstavisionLogo
    },
    [PARTNER_NAME.LUNA_DEV]: {
      nameOfCompany: 'Luna Home',
      typeOfCompany: 'LLC.',
      singinLabel: safeTranslate('signIn', undefined, 'Sign In') as string,
      welcomeText: safeTranslate(
        'welcomeText',
        {
          partnerName: 'Luna AI'
        },
        'Welcome to Luna AI'
      ) as string,
      loginIllustration: LunaAppLandingLogo,
      logo: LunaAppLogo,
      universalLink: PARTNER_UNIVERSAL_LINK[PARTNER_NAME.LUNA_DEV],
      paymentSuccessfull: LunaPaymentSuccessful,
      paymentFailed: LunaPaymentDeclined,
      freeTrialLogo: LunaStartFreeTrial,
      multiCameraAddedIllustration: LunaAllCamerasAddedToAccount,
      cannotAddDeviceIcon: LunaCannotAddSelectedCamera,
      trialActivationIcon: LunaFreeTrialActivated,
      label: 'Luna AI',
      supportEmail: 'support@lunahomeai.com',
      supportEmail2: 'lunasupport@lunahome.com',
      phoneNumber: '+1 (855) 604-5862',
      companyAddress: '450 North 1500 West Orem, Utah 84057',
      freeTrialLivingRoom: EventVideos30Days,
      freeTrialPersonDetected: LunaDescriptiveAlerts,
      freeTrialCriticalAlerts: LunaProfessionalMonitoring,
      skipFreeTrialLivingRoom: NoEventVideos,
      skipFreeTrialTheft: LimitedSecurity,
      skipFreeTrialFalseAlerts: LunaBasicAlerts,
      updateDeteOfPrivacyPolicy: 'September 11, 2024',
      updateDeteOfTermsOfService: 'September 11, 2024',
      smallWhiteLogo: LunaSmallWhiteLogo
    },
    [PARTNER_NAME.LUNA_STAGE]: {
      nameOfCompany: 'Luna Home',
      typeOfCompany: 'LLC.',
      singinLabel: safeTranslate('signIn', undefined, 'Sign In') as string,
      welcomeText: safeTranslate(
        'welcomeText',
        {
          partnerName: 'Luna AI'
        },
        'Welcome to Luna AI'
      ) as string,
      loginIllustration: LunaAppLandingLogo,
      logo: LunaAppLogo,
      universalLink: PARTNER_UNIVERSAL_LINK[PARTNER_NAME.LUNA_STAGE],
      paymentSuccessfull: LunaPaymentSuccessful,
      paymentFailed: LunaPaymentDeclined,
      freeTrialLogo: LunaStartFreeTrial,
      multiCameraAddedIllustration: LunaAllCamerasAddedToAccount,
      cannotAddDeviceIcon: LunaCannotAddSelectedCamera,
      trialActivationIcon: LunaFreeTrialActivated,
      label: 'Luna AI',
      supportEmail: 'support@lunahomeai.com',
      supportEmail2: 'lunasupport@lunahome.com',
      phoneNumber: '+1 (855) 604-5862',
      companyAddress: '450 North 1500 West Orem, Utah 84057',
      freeTrialLivingRoom: EventVideos30Days,
      freeTrialPersonDetected: LunaDescriptiveAlerts,
      freeTrialCriticalAlerts: LunaProfessionalMonitoring,
      skipFreeTrialLivingRoom: NoEventVideos,
      skipFreeTrialTheft: LimitedSecurity,
      skipFreeTrialFalseAlerts: LunaBasicAlerts,
      updateDeteOfPrivacyPolicy: 'September 11, 2024',
      updateDeteOfTermsOfService: 'September 11, 2024',
      smallWhiteLogo: LunaSmallWhiteLogo
    },
    [PARTNER_NAME.LUNA_PROD]: {
      nameOfCompany: 'Luna Home',
      typeOfCompany: 'LLC.',
      singinLabel: safeTranslate('signIn', undefined, 'Sign In') as string,
      welcomeText: safeTranslate(
        'welcomeText',
        {
          partnerName: 'Luna AI'
        },
        'Welcome to Luna AI'
      ) as string,
      loginIllustration: LunaAppLandingLogo,
      logo: LunaAppLogo,
      universalLink: PARTNER_UNIVERSAL_LINK[PARTNER_NAME.LUNA_PROD],
      paymentSuccessfull: LunaPaymentSuccessful,
      paymentFailed: LunaPaymentDeclined,
      freeTrialLogo: LunaStartFreeTrial,
      multiCameraAddedIllustration: LunaAllCamerasAddedToAccount,
      cannotAddDeviceIcon: LunaCannotAddSelectedCamera,
      trialActivationIcon: LunaFreeTrialActivated,
      label: 'Luna AI',
      supportEmail: 'support@lunahomeai.com',
      supportEmail2: 'lunasupport@lunahome.com',
      phoneNumber: '+1 (855) 604-5862',
      companyAddress: '450 North 1500 West Orem, Utah 84057',
      freeTrialLivingRoom: EventVideos30Days,
      freeTrialPersonDetected: LunaDescriptiveAlerts,
      freeTrialCriticalAlerts: LunaProfessionalMonitoring,
      skipFreeTrialLivingRoom: NoEventVideos,
      skipFreeTrialTheft: LimitedSecurity,
      skipFreeTrialFalseAlerts: LunaBasicAlerts,
      updateDeteOfPrivacyPolicy: 'September 11, 2024',
      updateDeteOfTermsOfService: 'September 11, 2024',
      smallWhiteLogo: LunaSmallWhiteLogo
    }
  };

  return partnerConfigs[partnerId] || partnerConfigs[PARTNER_NAME.DEFAULT];
};

export const getPartnerName = (partnerId: string | number) => {
  return PARTNER_SHORT_NAME[partnerId];
};

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSubscriptionDetails } from 'services/api/subscriptionApi';
import { TSubScriptionState, TSubscriptionType } from 'typings';

export const fetchAllSubscription = createAsyncThunk(
  'spaces/fetchAllSubscription',
  async (payload, thunkAPI) => {
    try {
      const response = await getSubscriptionDetails();
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue('failed');
    }
  }
);

const initialSubscriptionState: TSubScriptionState = {
  subscriptionData: [],
  isLoading: false,
  selectedSubscriptionInfo: null
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: initialSubscriptionState,
  reducers: {
    clearSubscriptionData: (state) => {
      state.subscriptionData = [];
      state.selectedSubscriptionInfo = null;
    },
    updateSubscriptionData: (state, action) => {
      state.subscriptionData = action.payload;
    },
    setSelectedSubscriptionInfo: (state, action) => {
      state.selectedSubscriptionInfo = action.payload;
    },
    clearSelectedSubscriptionInfo: (state) => {
      state.selectedSubscriptionInfo = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subscriptionData = action.payload as TSubscriptionType[];
      })
      .addCase(fetchAllSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllSubscription.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

// Action creators are generated for each case reducer function
export const {
  clearSubscriptionData,
  updateSubscriptionData,
  setSelectedSubscriptionInfo,
  clearSelectedSubscriptionInfo
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;

/* eslint-disable @typescript-eslint/no-explicit-any */
import AmexCard from 'assets/cardLogos/amex.png';
import CreditCard from 'assets/cardLogos/credit-card-regular.svg';
import DiscoverCard from 'assets/cardLogos/discover.png';
import MasterCard from 'assets/cardLogos/mastercard.png';
import VisaCard from 'assets/cardLogos/visa.png';
import { auth } from 'configs/firebaseConfig';
import { homeSecurityPortalApi } from 'data/axios';
import dayjs from 'dayjs';
import { signOut } from 'firebase/auth';
import { getPartnerName } from 'partner_configuration';
import { TSupportedFeature } from 'typings';
import { FREE_TRIAL_DURATION, TOKEN_REFRESH_THRESHOLD } from './constants';

const firebaseLogout = () => {
  signOut(auth);
};

export const setLocalStorageItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getLocalStorageItem = (key: string) => {
  return localStorage.getItem(key);
};

export const removeLocalStorageItem = (key: string) => {
  localStorage.removeItem(key);
};

export const setRequestHeaders = (authToken: string) => {
  // const url: any = window.location.origin;
  // const partnerName = getPartnerName(url);
  setLocalStorageItem('auth-token', authToken);
  homeSecurityPortalApi.defaults.headers[
    'Authorization'
  ] = `Bearer ${authToken}`;
  // homeSecurityPortalApi.defaults.headers['Partner-ID'] = partnerName;
  // homeSecurityPortalApi.defaults.headers['Client-ID'] = 'web';
};

export const removeRequestHeaders = () => {
  // removeLocalStorageItem('auth-token');

  delete homeSecurityPortalApi.defaults.headers['Authorization'];
  delete homeSecurityPortalApi.defaults.headers['Partner-ID'];
  delete homeSecurityPortalApi.defaults.headers['Client-ID'];
  delete homeSecurityPortalApi.defaults.headers['X-Session-ID'];
  delete homeSecurityPortalApi.defaults.headers['X-Request-ID'];
  localStorage.clear();

  firebaseLogout();
};

type PlanData = {
  sku_id: string;
  level: number;
  name: string;
};

const planData: PlanData[] = [
  { sku_id: 'ZJkWuV4hp9eUn7XOWpsgEpSmVfz', level: 1, name: 'Basic plan' },
  { sku_id: 'ZJkWuXu0cnmul6yD1mjsYZqdY5Z', level: 1, name: 'Basic plan' },
  { sku_id: 'ZJkWubZWLzeLEtN4sOJcg37RWCY', level: 2, name: 'Multi-Standard' },
  { sku_id: 'ZJkWuVTRkISYUmL0izTsM5N19pt', level: 2, name: 'Multi-Standard' },
  {
    sku_id: 'ZJkWuUCZH1GGokomjgTV8c4KtsI',
    level: 3,
    name: 'Premium Security'
  },
  {
    sku_id: 'ZJkWuZ02nDssfHl91aUO3AXxLxH',
    level: 3,
    name: 'Premium Security'
  },
  { sku_id: 'ZJkWuWg4Wa0jreEthneCxO1rZPn', level: 4, name: 'Combo' },
  { sku_id: 'ZJkWuXRsXMgDVk6AMbMTBliGijd', level: 4, name: 'Combo' }
];

export function getPlanNameByLevel(level: number): string {
  const plan = planData.find((item) => item.level === level);
  return plan ? plan.name : '';
}

export function nextUpgradePlan(sku_id: string | undefined): string {
  if (!sku_id) return '';
  const currentPlan = planData.find((plan) => plan.sku_id === sku_id);
  if (currentPlan) {
    const currentLevel = currentPlan.level;
    const nextLevel = currentLevel + 1;

    const nextPlan = planData.find((plan) => plan.level === nextLevel);
    if (nextPlan) {
      return nextPlan.name;
    }
  }
  return '';
}

export function isCardValid(cardMonth: number, cardYear: number) {
  const currentMonth = dayjs().month() + 1;
  const currentYear = dayjs().year();
  if (currentYear > cardYear) {
    return false;
  }
  if (currentYear === cardYear) {
    if (currentMonth > cardMonth) {
      return false;
    }
    return true;
  }
  return true;
}

export function calculateRemainingDays(trialStartTime: string) {
  const startDate = dayjs(trialStartTime);
  const endDate = startDate.add(FREE_TRIAL_DURATION, 'day');
  const currentDate = dayjs();
  const remainingDays = endDate.diff(currentDate, 'day');
  return remainingDays;
}

export function getPartnerId() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const url: any = window.location.origin;
  const partnerName = getPartnerName(url);
  return partnerName;
}

export const getCardImageProps = (brand: string) => {
  switch (brand) {
    case 'visa':
      return { src: VisaCard, width: '52', height: '16' };
    case 'mastercard':
      return { src: MasterCard, width: '40', height: '30' };
    case 'amex':
      return { src: AmexCard, width: '40', height: '16' };
    case 'discover':
      return { src: DiscoverCard, width: '40', height: '30' };
    default:
      return { src: CreditCard, width: '40', height: '30' };
  }
};

/**
 * Decodes a Base64URL-encoded string.
 * @param base64Url - The Base64URL string to decode.
 * @returns The decoded JSON object.
 */
const decodeBase64Url = (base64Url: string): any => {
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${c.charCodeAt(0).toString(16).padStart(2, '0')}`)
      .join('')
  );
  return JSON.parse(jsonPayload);
};

/**
 * Checks if the expiry time of a JWT token is less than 5 minutes.
 * @param token - The JWT access token.
 * @returns boolean - True if the token expires in less than 5 minutes, otherwise false.
 */
export const isTokenExpiringSoon = (token: string): boolean => {
  try {
    // console.log('Received token:', token);
    const [, payloadBase64] = token.split('.');
    if (!payloadBase64) {
      throw new Error('Invalid token format');
    }

    // console.log('Decoded Base64 payload:', payloadBase64); // Log the Base64 payload

    const payload = decodeBase64Url(payloadBase64);
    // console.log('Decoded token payload:', payload); // Log the decoded payload

    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    // console.log('Current time (seconds):', currentTime);

    if (!payload.exp) {
      throw new Error('Token does not have an expiry time');
    }

    // console.log('Token expiry time (seconds):', payload.exp); // Log the token expiry time
    // console.log(
    //   'Time until expiry (seconds):',
    //   payload.exp - currentTime
    // ); // Log the remaining time until expiry

    const isExpiringSoon = payload.exp - currentTime < TOKEN_REFRESH_THRESHOLD;
    // console.log('Is token expiring soon:', isExpiringSoon); // Log the result

    return isExpiringSoon;
  } catch (error) {
    console.error('Error parsing token:', error);
    return false;
  }
};

export const formatPrice = (
  priceInCents: number = 0,
  currency: string = 'USD'
): string => {
  const roundedPrice = Math.floor(priceInCents) / 100; // Apply floor rounding
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return formatter.format(roundedPrice);
};

export const getSupportedCameraCount = (
  supported_features: TSupportedFeature[]
) => {
  const cameraFeature = supported_features.find(
    (item: TSupportedFeature) => item.id === 'cameras_supported'
  );
  return cameraFeature?.value || 0;
};

export const amplitudeEvents = {
  USER_LOGIN: 'user_login',
  SCREEN_VIEW_LOGIN_PAGE: 'screen_view_login_page',
  SCREEN_VIEW_AUTHORIZING_PAGE: 'screen_view_authorizing_page', // added
  SCREEN_VIEW_CAMERA_INFO: 'screen_view_camera_info', // added
  SCREEN_VIEW_PROMO_PAGE_1: 'screen_view_promo_page_1', // added
  SCREEN_VIEW_PROMO_PAGE_2: 'screen_view_promo_page_2', // added
  CAMERA_INFO: 'camera_info', // added
  TRIAL_INFO: 'trial_info', // done
  TRIAL_INITIATED: 'trial_initiated', // done
  TRIAL_SKIP_FROM_PROMO_PAGE_1: 'trial_skip_from_promo_page_1', // added
  TRIAL_SKIP_FROM_PROMO_PAGE_2: 'trial_skip_from_promo_page_2', // added
  SCREEN_VIEW_PLAN_LIST: 'screen_view_plan_list', // added
  SELECTION_MONTHLY_BILLING: 'selection_monthly_billing', // added
  SELECTION_YEARLY_BILLING: 'selection_yearly_billing', // added
  SCREEN_VIEW_NAVIGATE_TO_APP: 'screen_view_navigate_to_app', // added
  NAVIGATE_BACK_TO_APP: 'navigate_back_to_app', // added
  PAYMENT_METHOD: 'payment_method', // check
  CREDIT_CARD_INFO_SUCCESS: 'credit_card info_success', // done
  CREDIT_CARD_INFO_CANCEL: 'credit_card_info_cancel', // done
  TRIAL_CONFIRMED: 'trial_confirmed', // done
  TRIAL_SKIPPED: 'trial_skipped', // done
  TRIAL_SKIPPED_REASON: 'trial_skipped_reason', // done
  TRIAL_CANCEL: 'trial_cancel', // done
  SCREEN_VIEW_USER_SUBSCRIPTION_INFO: 'screen_view_user_subscription_info', // added
  SUBSCRIPTION_MODIFY: 'subscription_modify', // done
  SUBSCRIPTION_UPGRADE: 'subscription_upgrade', // added
  SUBSCRIPTION_UPGRADE_FAILURE: 'subscription_upgrade_failure',
  SUBSCRIPTION_UPGRADE_SUCCESS: 'subscription_upgrade_success',
  FREE_TRIAL_MODIFY: 'free_trial_modify', // added
  FREE_TRIAL_CANCEL: 'free_trial_cancel', // added
  FREE_TRIAL_UPGRADE: 'free_trial_upgrade', // added
  FREE_TRIAL_UPGRADE_FAILURE: 'free_trial_upgrade_failure', // added
  FREE_TRIAL_UPGRADE_SUCCESS: 'free_trial_upgrade_success', // added
  SUBSCRIPTION_PURCHASE_FAILURE: 'subscription_purchase_failure', // added
  SUBSCRIPTION_PURCHASE_SUCCESS: 'subscription_purchase_success', // added
  SUBSCRIPTION_CANCEL_CLICKED: 'subscription_cancel_clicked', // added
  SUBSCRIPTION_AMOUNT: 'subscription_amount', // done
  SUBSCRIPTION_BUY_NOW: 'subscription_buy_now', // done,
  SUBSCRIPTION_CANCEL: 'subscription_cancel' // done
};

export enum TOKEN {
  AUTH_TOKEN = 'auth-token',
  REFRESH_TOKEN = 'refresh-token'
}

export const FREE_TRIAL_DURATION = 7;

export const maxCameraLimit = 5; // maximum no of camera allowed by any high tier plan

export const TOKEN_REFRESH_THRESHOLD = 5 * 60; // 5 minutes => calls refresh token logic when access token is about to expire in 5 minutes

export const PLAN_DETAILS = {
  MONTHLY: {
    SOLO: {
      LABEL: 'Monthly',
      CYCLE: 1
    },
    ENHANCED: {
      LABEL: 'Enhanced',
      CYCLE: 1
    },
    PREMIUM: {
      LABEL: 'Premium',
      CYCLE: 1
    }
  },
  YEARLY: {
    SOLO: {
      LABEL: 'Monthly',
      CYCLE: 12
    },
    ENHANCED: {
      LABEL: 'Enhanced',
      CYCLE: 12
    },
    PREMIUM: {
      LABEL: 'Premium',
      CYCLE: 12
    }
  }
};
